<template>
  <v-container>
    <v-card
      class="mx-auto"
      max-width="320"
    >
      <v-card-text>
        <div class="text--primary">Station</div>
        <p class="text-h4 text--primary">
          #{{ stationNumber}}
        </p>
      </v-card-text>
      <v-expand-transition>
        <div
          v-if="show"
        > 
          <!-- OLD RALLY REBUSES THAT CAN BE OVERWRITTEN IN oldRallyRebuses DATA-->
          <div class="pb-0 px-4" v-if="this.gamlaRally && oldRallyRebuses.filter(({number}) => number === this.stationNumber).length > 0">
            <h4>Rebus:</h4>
            <!--If this station in oldRallyRebuses has a rebus, show it instead of the regular one-->
            <p>
              <span v-html="oldRallyRebuses.filter(({number}) => number === this.stationNumber)[0].rebus ? oldRallyRebuses.filter(({number}) => number === this.stationNumber)[0].rebus : rebus"></span>
            </p>
            <div v-for="(help, index) in helps" :key="index">
                <h4 v-if="index != 3">Hjälp {{ index + 1 }}:</h4>
                <h4 v-else>Facit:</h4>
                <!--If this station in oldRallyRebuses has a help, show it instead of the regular one-->
                <p>
                  <span v-html="oldRallyRebuses.filter(({number}) => number === stationNumber)[0].helps.filter(({number}) => number === index+1)[0] ? oldRallyRebuses.filter(({number}) => number === stationNumber)[0].helps.filter(({number}) => number === index+1)[0].help : help"></span>
                </p>
            </div>
            <v-btn
                  v-if="helps.length != 4"
                  color="primary"
                  class="mb-4"
                  dark
                  @click="dialog = true"
            >
                  {{ buttonText }}
            </v-btn>
          </div>
           
          <div class="pb-0 px-4" v-else>
            <h4>Rebus:</h4>
            <p><span v-html="rebus"></span></p>
            <div v-for="(help, index) in helps" :key="index">
                <h4 v-if="index != 3">Hjälp {{ index + 1 }}:</h4>
                <h4 v-else>Facit:</h4>
                <p><span v-html="help"></span></p>
            </div>
            <v-btn
                  v-if="helps.length != 4"
                  color="primary"
                  class="mb-4"
                  dark
                  @click="dialog = true"
            >
                  {{ buttonText }}
            </v-btn>
          </div>
          <div class="text-center">
      <!-- Hjälprebus eller facit -->
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Hej! Anna här!
          </v-card-title>
          <v-card-text>Hjälp från mig leder till tidspålägg.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="getHelp"
            >
              Ja
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Nej
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
        </div>
      </v-expand-transition>
      <v-card-actions class="flex justify-center">
        <v-icon
        color="primary"
        class="toggleUpDown"
        :class='{ "rotate": show }'
        @click="show = !show"
        >
        {{'mdi-chevron-down'}}
        </v-icon>
      </v-card-actions>

    </v-card>
  </v-container>
</template>

<style scoped>
.toggleUpDown {
    transition: transform .4s ease-in-out !important;  
}

.toggleUpDown.rotate {
    transform: rotate(180deg);
}
</style>

<script>
import { mapActions, mapGetters} from "vuex"
import {notify} from '../utils/notification'
const he = require('he')
export default {
    name: 'OpenStationComponent',
    props: {
        stationNumber: Number,
        gamlaRally: Boolean
    },
    data: () => ({
        dialog: false,
        show: false,
        buttonText: 'Vi suger snälla hjälp',
        rebus: '',
        helps: [],
        oldRallyRebuses: [
          {
            number: 2,
            helps: [
              {
                number: 1,
                help:"<p> Hur kan ni inte fatta det???? </p>" 
              }
            ]
          },
          {
            number: 3,
            rebus: "<p> 'Ej'+' '+'Sundsvalls tidning'{kort} + \"vattenorm\" + {\"slippery tube dude\" & \"huggis\"} - 'r' + 'advice'{sve} + \"alien som vill åka hem\" </p>", 
            helps: []
          },
          {
            number: 7,
            helps: [
              {
                number: 2,
               help: "<p> Noobs </p>"
              }
            ]
          },
          {
            number: 8,
            helps: [
              {
                number: 1,
                help: "<p> Men vafan, lös det bara </p>"
              }
            ]
          }
        ]
    }),
    methods: {
    ...mapActions(["getStationHelp", "getStationAvailableHelp", "getRebus", "clearNotifications"]),
    async getHelp() {
      this.dialog = false

      await this.getStationHelp(this.stationNumber)

      // Notification
      const success = notify(this)
      if (success) {
        this.updateProgress()
      }
    },
    async updateProgress() {
      // Fetch all unlocked help
      const helps = await this.getStationAvailableHelp(this.stationNumber)
      // Convert from object to array for easier looping
      this.helps = []
      for (const key in helps) {
        const decodedHelp = he.decode(helps[key])
        this.helps.push(decodedHelp)
      }
    },
    async setRebus() {
      const stationNumber = this.stationNumber
      const rebus = await this.getRebus(stationNumber)
      const decodedRebus = he.decode(rebus)
      this.rebus = decodedRebus
    }
  },
  computed: mapGetters(["notification"]),
  // When component is entered, show already unlocked help
  mounted() {
    this.updateProgress()
    // When the station is already unlocked, we simply get the rebus
    this.setRebus()
  }

}
</script>

<style>

</style>