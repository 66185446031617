<template>
  <v-container>    
    <div v-if="team && team.t_dished">
      <h2>Laget är diskvalificerat. Kontakta oss för mer information.</h2>

    </div>
    <div v-else>
    
    <h2>Stationer</h2>
    <h5>Lös ReBuS kluriga rebusar, mät och åk till stationen</h5>
    
    <div v-if="fetchingResults" class="center">
      <vue-spinner />
    </div>

    <v-container v-else>  

      <div v-if="team && team.t_gamla_rallyt" class="moving-image-container">
        <v-img
        :src="require('@/assets/anna_basic.png')"
        class="moving-image"
         alt="Moving Image"
        contain
      ></v-img>
      </div>

      <!-- The card for the locked station. Disable for the last station -->
      <v-card
        class="mx-auto"
        max-width="320"
        v-if="unlockedStations !== this.numStations"
      >
        <v-card-text>
          <div class="text--primary">Nästa Station</div>
          <p class="text-h4 text--primary">
            #{{ unlockedStations + 1 }}
          </p>
        </v-card-text>
        <p class="px-2">Skanna QR-koden för att låsa upp nästa station</p>
        <v-card-actions class="justify-center">
          <v-btn
            text
            color="primary"
            @click="reveal = true"
            v-if="!reveal"
          >
            Lås upp station
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="reveal = false"
            v-if="reveal"
          >
            Stäng
          </v-btn>
        </v-card-actions>

        <v-expand-transition v-if="team">
          <div
            v-if="reveal"
            :style="{ transform: team.t_gamla_rallyt ? 'scaleY(-1)' : ''}"
          >
          <qrcode-stream @decode="onDecode" @init="onInit" />
        </div>
        </v-expand-transition>
      </v-card>

      <!-- Cards for the opened stations -->
      <div :key="unlockedStations" v-if="team">
        <open-station-component
          v-for="index in unlockedStations"
          :key="index"
          :stationNumber="unlockedStations-index+1" :gamlaRally="team.t_gamla_rallyt"/>
      </div>
  </v-container>
</div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import {notify} from '../../utils/notification'
import OpenStationComponent from '../../components/OpenStationComponent.vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { useGeolocation } from '@vueuse/core'
import Spinner from "vue-simple-spinner"

export default {
  name: 'Stations',
  data: () => ({
    reveal: false,
    token: '',
    unlockedStations: null,
    numStations: null,
    qrResult: null,
    error: null,
    coords: null,
    coordsError: null,
    team: null,
    fetchingResults: true,
    timer: null,
  }),
  components: {
    QrcodeStream,
    OpenStationComponent,
    vueSpinner: Spinner
  },
  methods: {
    // Need to get t_unlock from server
    ...mapActions(["unlockStation", "getTeamUnlocks", "clearNotifications", "isAuth", "getTeam"]),
    async updateTeamUnlocks() {
      const data = await this.getTeamUnlocks()
      this.unlockedStations = data
    },
    async fetchTeam() {
      const team = await this.getTeam()
      this.team = team
    },
    async onDecode (decodedString) {
      
      if (this.coordsError) {
        console.log(this.coordsError)
      }

      this.qrResult = decodedString
      await this.unlockStation({
        stationNumber: this.unlockedStations + 1, 
        token: decodedString,
        latitude: this.coords.latitude,
        longitude: this.coords.longitude,
      })
      this.reveal = false
      await this.updateTeamUnlocks()

      // Notification
      notify(this)

    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
  },
  },
  computed: mapGetters(["notification"]),
  mounted() {
    // Get team unlocks
    this.numStations = parseInt(process.env.VUE_APP_NUMBER_OF_STATIONS)
    this.updateTeamUnlocks().then(() => {
      this.fetchingResults = false
    })
    this.fetchTeam()
    const { coords, error } = useGeolocation()
    this.coords = coords
    this.coordsError = error
    
    this.timer = setInterval(() => {
      this.updateTeamUnlocks()
      this.fetchTeam()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style>

.moving-image-container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 150px; 
  overflow: hidden;
  z-index: 999;
}

.moving-image {
  position: absolute;
  top: 0;
  left: -1200px; /* Start off the screen */
  width: 300px;
  height: auto;
  animation: moveAcross 5s linear infinite;
}

@keyframes moveAcross {
  0% {
    left: -1200px; /* Start off-screen on the left */
  }
  100% {
    left: 100%; /* Move off-screen to the right */
  }
}
</style>
